/**
 * custom ant-d
 * @author Tevin
 */

@import "./sassMixin";

body {
    font-family: "微软雅黑", "Microsoft YaHei", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: rgba(0, 0, 0, 0.85);
    .ant-layout {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .ant-btn {
        color: rgba(0, 0, 0, 0.85);
        &.ant-btn-sm.mini {
            height: 20px;
            line-height: 18px;
            letter-spacing: -1px;
            font-size: 12px;
        }
        &.ant-btn-primary {
            color: #fff;
            &.btn-success {
                background-color: #68c639;
                border-color: #68c639;
            }
            &.btn-info {
                background-color: #00b2c2;
                border-color: #00b2c2;
                &[disabled] {
                    background-color: rgba(#00b2c2, 0.15);
                    border-color: rgba(#00b2c2, 0.08);
                }
            }
            &.btn-warning {
                background-color: #c5a463;
                border-color: #c5a463;
            }
            &[disabled],
            &[disabled]:hover,
            &[disabled]:focus,
            &[disabled]:active {
                color: rgba(0, 0, 0, 0.25);
                background: #f5f5f5;
                border-color: #d9d9d9;
            }
        }
        &.ant-btn-dashed {
            &.btn-success {
                color: #4bb814;
                border-color: #68c639;
            }
            &.btn-info {
                color: #00b2c2;
                border-color: #00b2c2;
            }
            &.btn-warning {
                color: #cc8f00;
                border-color: #c5a463;
            }
        }
        &.ant-btn-danger,
        &.ant-btn-dangerous.ant-btn-primary {
            color: #fff;
            &[disabled],
            &[disabled]:hover,
            &[disabled]:focus,
            &[disabled]:active {
                color: rgba(0, 0, 0, 0.25);
                background: #f5f5f5;
                border-color: #d9d9d9;
            }
        }
        &.ant-btn-dangerous {
            color: #ff4d4f;
        }
        &.ant-btn-dangerous.ant-btn-danger {
            text-shadow: none;
        }
        &.ant-btn-link {
            color: #1890ff;
        }
        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus,
        &[disabled]:active {
            color: rgba(0, 0, 0, 0.25);
        }
    }
    .ant-input-affix-wrapper {
        &.ant-input-affix-wrapper-disabled {
            .ant-input-suffix {
                color: rgba(0, 0, 0, 0.65)
            }
        }
        .read-only {
            background-color: #fbfbfb;
        }
    }
    .ant-input {
        color: rgba(0, 0, 0, 0.85);
        &[readonly] {
            cursor: default;
            background-color: #fbfbfb;
        }
        &[disabled] {
            color: rgba(0, 0, 0, 0.85);
        }
    }
    .ant-input-number {
        &:focus,
        &.ant-input-number-focused {
            .ant-input-number-handler-wrap {
                opacity: 1;
            }
        }
    }
    .ant-table {
        z-index: 1;
        color: rgba(0, 0, 0, 0.85);
        &.ant-table-middle {
            tbody.ant-table-tbody>tr>td {
                padding: 9px 8px;
                line-height: 24px;
            }
        }
        &.ant-table-bordered {
            position: relative;
            &::after {
                @include position(absolute, n 0 0 n, 3);
                width: 100%;
                height: 0;
                border-bottom: #f0f0f0 1px solid;
                content: " ";
            }
        }
        td.ant-table-column-sort {
            background-color: rgba(0, 0, 0, .02);
        }
        .ant-table-summary {
            .ant-table-cell {
                @include prefixer-val(position, sticky);
                bottom: 0;
                z-index: 3;
                padding: 9px 8px;
                line-height: 24px;
                background: #fafafa;
                &:after {
                    display: block;
                    @include position(absolute, -1px 0 n n);
                    width: 100%;
                    height: 0;
                    border-top: 1px solid #f0f0f0;
                    content: " ";
                }
            }
        }
    }
    .ant-form-horizontal {
        padding-bottom: 20px;
        .ant-row {
            &.ant-form-item {
                &>.ant-col.ant-form-item-label>label {
                    white-space: normal;
                    line-height: 18px;
                }
                .ant-form-item-extra {
                    color: rgba(0, 0, 0, 0.45);
                    .c-placeholder-link {
                        opacity: 0.4;
                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
            &.ant-form-item-hidden {
                display: none;
            }
        }
        .ant-divider-with-text-center,
        .ant-divider-with-text-left {
            padding: 16px 0 24px;
            margin: 0;
            border-top: transparent 1px solid;
        }
        .ant-input-number {
            width: 100%;
        }
    }
    .ant-form-inline {
        .ant-row {
            &.ant-form-item-hidden {
                display: none;
            }
        }
    }
    // 表单无权限
    .ant-form {
        // 输入框
        .ant-input-affix-wrapper-disabled.no-permission {
            .ant-input-suffix {
                &::before {
                    display: inline-block;
                    margin-right: -12px;
                    color: $colorIgnore;
                    content: "（无权限）";
                    opacity: 0;
                    pointer-events: none;
                    transition: opacity 0.15s;
                }
            }
            &:hover {
                .ant-input-suffix {
                    &::before {
                        opacity: 1;
                    }
                }
            }
        }
        // 单选
        .ant-radio-group.no-permission {
            &::after {
                display: inline-block;
                color: $colorIgnore;
                font-size: 14px;
                content: "（无权限）";
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.15s;
            }
            &:hover::after {
                opacity: 1;
            }
        }
        // 下拉选框
        .c-plain-select.no-permission {
            .ant-select-arrow {
                &::after {
                    display: inline-block;
                    width: 72px;
                    margin-left: -50px;
                    margin-top: -2px;
                    color: $colorIgnore;
                    font-size: 14px;
                    vertical-align: bottom;
                    content: "（无权限）";
                    opacity: 0;
                    pointer-events: none;
                    transition: opacity 0.15s;
                }
                .ant-select-suffix {
                    display: none;
                }
            }
            &:hover {
                .ant-select-arrow {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .ant-descriptions {
        .ant-descriptions-item-label {
            color: rgba(0, 0, 0, 0.85);
        }
        .ant-descriptions-item-content {
            color: rgba(0, 0, 0, 1);
            word-break: break-all;
        }
    }
    .ant-tabs {
        color: rgba(0, 0, 0, 0.85);
        &.ant-tabs-card {
            .ant-tabs-card-bar {
                .ant-tabs-tab {
                    font-weight: normal;
                    color: rgba(0, 0, 0, 0.85);
                    &:hover {
                        color: rgba(0, 0, 0, 0.85);
                        background-color: #f7f7f7;
                    }
                }
                .ant-tabs-tab-active {
                    color: rgba(0, 0, 0, 0.85);
                    &:hover {
                        color: rgba(0, 0, 0, 0.85);
                        background-color: #fff;
                    }
                }
                .ant-tabs-tab-arrow-show {
                    &.ant-tabs-tab-prev,
                    &.ant-tabs-tab-next {
                        color: rgba(0, 0, 0, 0.85);
                    }
                    &.ant-tabs-tab-prev:hover,
                    &.ant-tabs-tab-next:hover {
                        color: rgba(0, 0, 0, 0.95);
                    }
                    &.ant-tabs-tab-btn-disabled,
                    &.ant-tabs-tab-btn-disabled:hover {
                        color: rgba(0, 0, 0, 0.25);
                    }
                    .ant-tabs-tab-prev-icon-target,
                    .ant-tabs-tab-next-icon-target {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .ant-spin {
        .anticon-loading {
            font-size: 35px;
        }
    }
    .ant-divider {
        margin: 14px 0;
        color: rgba(0, 0, 0, 0.3);
    }
    .ant-radio-group {
        color: rgba(0, 0, 0, 0.85);
        .ant-radio-wrapper {
            color: rgba(0, 0, 0, 0.85);
        }
        .ant-radio-checked {
            &+span {
                color: rgba(0, 0, 0, 0.7);
                font-weight: bold;
            }
            &.ant-radio-disabled+span {
                color: rgba(0, 0, 0, 0.25);
            }
        }
        .ant-radio-button-wrapper {
            color: rgba(0, 0, 0, 0.85);
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            color: #fff;
        }
        &.ant-radio-group-outline {
            .ant-radio-button-wrapper {
                padding: 0 10px;
            }
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                color: #1890ff;
            }
        }
    }
    .ant-switch {
        &.switch-fat {
            height: 26px;
            border: rgba(0, 0, 0, 0) 2px solid;
            &.ant-switch-checked {
                border-color: #1890ff;
            }
        }
    }
    .ant-select {
        color: rgba(0, 0, 0, 0.85);
        &.ant-select-disabled,
        &.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) {
            .ant-select-selector {
                color: rgba(0, 0, 0, 0.85);
            }
        }
    }
    .ant-select-dropdown {
        .ant-select-item-option-active {
            font-weight: bold;
            background-color: #ebebeb;
        }
    }
    .ant-checkbox-group {
        color: rgba(0, 0, 0, 0.85);
        .ant-checkbox-wrapper {
            color: rgba(0, 0, 0, 0.85);
        }
    }
    .ant-cascader-picker {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-picker {
        color: rgba(0, 0, 0, 0.85);
        &.ant-picker-disabled input[disabled] {
            color: rgba(0, 0, 0, 0.85);
        }
        .ant-picker-input {
            >input {
                color: rgba(0, 0, 0, 0.85);
            }
        }
    }
    .ant-picker-dropdown {
        max-width: 100%;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: -5px;
        .ant-picker-panel-container {
            overflow-x: auto;
        }
    }
    .ant-steps {
        color: rgba(0, 0, 0, 0.85);
        .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
            color: rgba(0, 0, 0, 0.85);
        }
    }
    .ant-modal {
        color: rgba(0, 0, 0, 0.85);
        .ant-modal-confirm-body {
            .ant-modal-confirm-title {
                color: rgba(0, 0, 0, 1);
            }
            .ant-modal-confirm-content {
                color: rgba(0, 0, 0, 0.85);
            }
        }
        div[aria-hidden="true"] {
            display: none !important;
        }
        .ant-tabs-content-animated {
            div[aria-hidden="true"] {
                display: block !important;
            }
        }
    }
    .ant-pagination {
        color: rgba(0, 0, 0, 0.85);
        .ant-pagination-item a {
            color: rgba(0, 0, 0, 0.85);
        }
        .ant-pagination-item-active a {
            color: #1890ff;
        }
    }
    .ant-list {
        color: rgba(0, 0, 0, 0.85);
        .ant-list-item {
            color: rgba(0, 0, 0, 0.85);
        }
    }
    .ant-card {
        color: rgba(0, 0, 0, 0.85);
        .ant-card-head {
            color: rgba(0, 0, 0, 0.95);
        }
    }
    .ant-popover-message {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-tree {
        color: rgba(0, 0, 0, 0.85);
    }
}